export default () => ({
	status: 'not-submitted',
	errors: {},
	submitData: function() {
		let formsData = new FormData(this.$refs.form);
		if(localStorage.getItem('source') !== ""){
			formsData.set("utm_source", localStorage.getItem('source'));
			formsData.set("utm_medium", localStorage.getItem('medium'));
			formsData.set("utm_campaign", localStorage.getItem('campaign'));
		}
		fetch(this.$refs.form.getAttribute('action'), {
			method: 'POST',
			mode: 'same-origin',
			headers: { 'X-CSRFToken': document.querySelector('meta[name=csrf-token]').content },
			body: formsData
		})
		.then((resp) => resp.json())
		.then((data) => {
			this.status = data.status;
			this.errors = (data.errors ? data.errors : {});
			if(data.status === 'success' && 'redirect' in data) {
				window.location = data.redirect;
			}
		})
		.catch(() => {
			this.status = 'error';
		});
	}
});